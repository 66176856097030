export const menuItems = [
    {
        id: 1,
        label: "Items",
        isTitle: true
    },
    {
        id: 124,
        label: "Multiple Choice",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1241,
                label: "MC",
                link: "q2-choice-interaction-single-cardinality",
                parentId: 124
            },
            {
                id: 1242,
                label: "MC w/shuffle",
                link: "q2-choice-interaction-single-shuffle",
                parentId: 124
            },
            {
                id: 1243,
                label: "MC no labels",
                link: "q2-choice-interaction-single-sv-2a",
                parentId: 124
            },
            {
                id: 1244,
                label: "MC ctrl options",
                link: "q2-choice-interaction-single-sv-4a",
                parentId: 124
            },
            {
                id: 1245,
                label: "MC orientation",
                link: "q2-choice-interaction-single-sv-4b",
                parentId: 124
            },
            {
                id: 1246,
                label: "MC stacking",
                link: "q2-choice-interaction-single-sv-4c",
                parentId: 124
            },
            {
                id: 1247,
                label: "MC bubble",
                link: "q2-choice-interaction-single-cardinality-bubble",
                parentId: 124
            }
        ]
    },
    {
        id: 125,
        label: "Multiple Select",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1251,
                label: "MS",
                link: "q2-choice-interaction-multiple",
                parentId: 125
            },
            {
                id: 1252,
                label: "MS w/shuffle",
                link: "q2-choice-interaction-multiple-shuffle",
                parentId: 125
            },
            {
                id: 1253,
                label: "MS no labels",
                link: "q2-choice-interaction-multiple-sv-2d",
                parentId: 124
            },
            {
                id: 1254,
                label: "MS ctrl options",
                link: "q2-choice-interaction-multiple-sv-4a",
                parentId: 125
            },
            {
                id: 1255,
                label: "MS orientation",
                link: "q2-choice-interaction-multiple-sv-4b",
                parentId: 125
            },
            {
                id: 1256,
                label: "MS stacking",
                link: "q2-choice-interaction-multiple-sv-4c",
                parentId: 125
            },
            {
                id: 1257,
                label: "MS min/max selections",
                link: "q2-choice-interaction-multiple-sv-4d",
                parentId: 125
            }
        ]
    },
    {
        id: 130,
        label: "Inline Choice",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1301,
                label: "IC",
                link: "q12-inline-choice-interaction",
                parentId: 130
            },
            {
                id: 1302,
                label: "IC w/shuffle",
                link: "q12-inline-choice-shuffle",
                parentId: 130
            },
            {
                id: 1303,
                label: "IC w/mathml",
                link: "q12-inline-choice-mathml",
                parentId: 130
            },
            {
                id: 1304,
                label: "IC widths",
                link: "q12-inline-choice-sv-1",
                parentId: 130
            },
            {
                id: 1305,
                label: "IC w/custom prompt",
                link: "q12-inline-choice-sv-2",
                parentId: 130
            },
            {
                id: 1306,
                label: "IC w/entities",
                link: "q12-inline-choice-entity-test",
                parentId: 130
            }
        ]
    },
    {
        id: 131,
        label: "Text Entry",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1311,
                label: "Plain FIB w/mapresponse",
                link: "q20-textentry-mapresponse",
                parentId: 131
            },
            {
                id: 1314,
                label: "Input Widths",
                link: "q20-textentry-composite",
                parentId: 131
            },
            {
                id: 1315,
                label: "Pattern Mask",
                link: "q20-textentry-sv-3",
                parentId: 131
            }
        ]
    },
    {
        id: 134,
        label: "Order",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1341,
                label: "Grand Prix - default",
                link: "q15-order-example-1",
                parentId: 134
            },
            {
                id: 1342,
                label: "Grand Prix - horizontal",
                link: "q15-order-example-2",
                parentId: 134
            },
            {
                id: 1343,
                label: "Grand Prix - vertical",
                link: "q15-order-example-3",
                parentId: 134
            },
            {
                id: 1344,
                label: "Brown Fox - w/shuffle",
                link: "q15-order-example-4",
                parentId: 134
            },
            {
                id: 1345,
                label: "Target label/suffix Options",
                link: "q15-order-interaction-sv-1",
                parentId: 134
            },
            {
                id: 1346,
                label: "Orderable-Target Orientation",
                link: "q15-order-interaction-sv-2",
                parentId: 134
            },
        ]
    },
    {
        id: 132,
        label: "Equation Editor",
        icon: "mdi-apps",
        subItems: [
            {
                id: 1321,
                label: "EE w/Basic Operators",
                link: "card-03-ee-basic-operators",
                parentId: 132
            },
            {
                id: 1322,
                label: "EE w/Basic Num",
                link: "card-03-ee-basic-numeric",
                parentId: 132
            },
            {
                id: 1323,
                label: "EE w/Basic Num Frac",
                link: "card-03-ee-basic-numeric-fraction",
                parentId: 132
            },
            {
                id: 1324,
                label: "EE w/All Palettes",
                link: "card-03-ee-all-palettes",
                parentId: 132
            },
            {
                id: 1325,
                label: "EE w/Operators IsEquivalent",
                link: "card-08a-ee-baseline",
                parentId: 132
            }
        ]
    },
    {
        id: 126,
        label: "Templating",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1261,
                label: "MS w/math templating",
                link: "ms-choice-templated-qti3",
                parentId: 126
            },
            {
                id: 1262,
                label: "Image templating",
                link: "template_image",
                parentId: 126
            },
            {
                id: 1263,
                label: "Printed variable templating",
                link: "amp-04-nextgen",
                parentId: 126
            },
            {
                id: 1264,
                label: "Statistics operators",
                link: "stats-05-nextgen",
                parentId: 126
            },
            {
                id: 1265,
                label: "TDX-baseline",
                link: "card-08a-baseline",
                parentId: 126
            },
            {
                id: 1266,
                label: "TDX-showex",
                link: "adaptivecard-08c-showexample",
                parentId: 126
            },
            {
                id: 1267,
                label: "TDX-solve",
                link: "adaptivecard-08b-solvethis",
                parentId: 126
            }
        ]
    },
    {
        id: 127,
        label: "Adaptive",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1271,
                label: "Too Many Cooks",
                link: "Example05-feedbackBlock-adaptive-qti3-card",
                parentId: 127
            },
            {
                id: 1272,
                label: "Show Solution",
                link: "Example03-feedbackBlock-solution-qti3",
                parentId: 127
            },
            {
                id: 1273,
                label: "Monty Hall (Take 2)",
                link: "MontyHallTake2",
                parentId: 127
            }
        ]
    },
    {
        id: 133,
        label: "Catalog",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1331,
                label: "Glossary",
                link: "a13-a15-captions-glossary",
                parentId: 133
            },
            {
                id: 1332,
                label: "Glossary and Keyword Translation",
                link: "glossary-keywordtrans",
                parentId: 133
            }
        ]
    },
    {
        id: 15,
        label: "Hotspot",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1247,
                label: "Single Cardanility Circle",
                link: "Q10 Hotspot Interaction - Single Cardinality Circle",
                parentId: 125
            },{
                id: 1250,
                label: "Single Cardanility Rectangle",
                link: "Q10 Hotspot Interaction - Single Cardinality Rectangle",
                parentId: 124
            },
            {
                id: 1248,
                label: "Multiple Cardanility",
                link: "Q10 Hotspot Interaction - Multiple Cardinality",
                parentId: 125
            },
            {
                id: 1249,
                label: "Text",
                link: "Q10 Hotspot Text",
                parentId: 125
            }
        ]
    },
    {
        id: 16,
        label: "Fill in the blanks",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1243,
                label: "Drag to fill- Choices Top",
                link: "Q10 Drag to fill-top",
                parentId: 16
            },
            {
                id: 1244,
                label: "Drag to fill- Choices Bottom",
                link: "Q10 Drag to fill-bottom",
                parentId: 16
            },
            {
                id: 1245,
                label: "Drag to fill - Choices Left - Data Choices Container Width-200",
                link: "Q10 Drag to fill-left",
                parentId: 16
            },
            {
                id: 1246,
                label: "Drag to fill - Choices Right - Data Choices Container Width-200",
                link: "Q10 Drag to fill-right",
                parentId: 16
            },
            {
                id: 1247,
                label: "Drag to fill- Choices Left - Data Choices Container Width-100",
                link: "Q10 Drag to fill-left-100",
                parentId: 16
            },
            {
                id: 1248,
                label: "Drag to fill- Choices Right - Data Choices Container Width-100",
                link: "Q10 Drag to fill-right-100",
                parentId: 16
            },
            {
                id: 1249,
                label: "Drag to fill- Input Width",
                link: "Q10 Drag to Input Width",
                parentId: 16
            },
            {
                id: 1251,
                label: "Drag to fill- Max Association",
                link: "Q16 Fill in the blanks 1",
                parentId: 16
            },
            {
                id: 1252,
                label: "FIB - Enter Numbers Only",
                link: "Q16 Fill in the blanks 2",
                parentId: 16
            },
            {
                id: 1257,
                label: "FIB - Inline Choices",
                link: "Q16 Fill in the blanks 3",
                parentId: 16
            },
            {
                id: 1258,
                label: "FIB - Text Entry",
                link: "Q16 Fill in the blanks 4",
                parentId: 16
            },
    ]
    },
    {
        id: 17,
        label: "Sort into container",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1244,
                label: "Simple choice",
                link: "Q10 Simple choice",
                parentId: 17
            }
        ]
    },
    {
        id: 18,
        label: "Label the diagram",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1247,
                label: "Graphic gap match - Choices Top",
                link: "Q17 Label the diagram 2",
                parentId: 18
            },
            {
                id: 1248,
                label: "Graphic gap match - Over Image",
                link: "Q17 Label the diagram 3",
                parentId: 18
            },
            {
                id: 1249,
                label: "Graphic gap match - Choice Tiles",
                link: "Q17 Label the diagram 4",
                parentId: 18
            }
        ]
    },
    {
        id: 134,
        label: "Multi-Item Composite",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1246,
                label: "Multi-Item Composite",
                link: "Multi-Item Composite",
                parentId: 124
            },
            {
                id: 1247,
                label: "Full Layout",
                link: "Multi-Item Composite: Full Layout",
                parentId: 134
            },
            {
                id: 1248,
                label: "Top-Bottom Layout",
                link: "Multi-Item Composite: Top-Bottom Layout",
                parentId: 134
            },
            {
                id: 1249,
                label: "Left-Right Layout",
                link: "Multi-Item Composite: Left-Right Layout",
                parentId: 134
            },
            {
                id: 1250,
                label: "Split-Top Layout",
                link: "Multi-Item Composite: Split-Top Layout",
                parentId: 134
            },
            {
                id: 1251,
                label: "Split-Bottom Layout",
                link: "Multi-Item Composite: Split-Bottom Layout",
                parentId: 134
            },
            {
                id: 1257,
                label: "Multi-Item Composite - Audio Type 1",
                link: "Multi-Item Composite Q1",
                parentId: 134
            },
            {
                id: 1258,
                label: "Multi-Item Composite - Audio Type 2",
                link: "Multi-Item Composite Q2",
                parentId: 134
            },
            {
                id: 1259,
                label: "Multi-Item Composite - Audio Type 3",
                link: "Multi-Item Composite Q3",
                parentId: 134
            },
            {
                id: 1259,
                label: "Paging Full Layout",
                link: "Multi-Item Composite Q4",
                parentId: 134
            },
            {
                id: 1260,
                label: "Tabbed Full Layout",
                link: "Multi-Item Composite Q5",
                parentId: 134
            },
            {
                id: 1261,
                label: "Scrolling Full Layout",
                link: "Multi-Item Composite Q6",
                parentId: 134
            },
            {
                id: 1262,
                label: "Separate Full Layout",
                link: "Multi-Item Composite Q7",
                parentId: 134
            },
            {
                id: 1263,
                label: "Separate Full Layout Without Static",
                link: "Multi-Item Composite Q8",
                parentId: 134
            },
            {
                id: 1264,
                label: "Paging Full Layout",
                link: "Multi-Item Composite Q9",
                parentId: 134
            },
            {
                id: 1265,
                label: "Tabbed Full Layout",
                link: "Multi-Item Composite Q10",
                parentId: 134
            },
            {
                id: 1266,
                label: "Scrolling Full Layout",
                link: "Multi-Item Composite Q11",
                parentId: 134
            },
            {
                id: 1267,
                label: "Paging Left-Right Layout",
                link: "Multi-Item Composite Q12",
                parentId: 134
            },
            {
                id: 1268,
                label: "Tabbed Left-Right Layout",
                link: "Multi-Item Composite Q13",
                parentId: 134
            },
            {
                id: 1269,
                label: "Scrolling Left-Right Layout",
                link: "Multi-Item Composite Q14",
                parentId: 134
            },
            {
                id: 1273,
                label: "Paging Split Top Layout",
                link: "Multi-Item Composite Q18",
                parentId: 134
            },
            {
                id: 1274,
                label: "Tabbed Split Top Layout",
                link: "Multi-Item Composite Q19",
                parentId: 134
            },
            {
                id: 1275,
                label: "Scrolling Split Top Layout",
                link: "Multi-Item Composite Q20",
                parentId: 134
            }
        ]
    },
    {
        id: 135,
        label: "Extended/Short Constructive Response",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1252,
                label: "Extended Rich Text - Height 15 Lines",
                link: "Short Constructive Response Q1",
                parentId: 135
            },
            {
                id: 1253,
                label: "Extended Rich Text - Height 3 Lines",
                link: "Short Constructive Response Q2",
                parentId: 135
            },
            {
                id: 1254,
                label: "Extended Rich Text - Input disabled",
                link: "Short Constructive Response Q3",
                parentId: 135
            },
            {
                id: 1255,
                label: "Extended Rich Text - Input max length 100 characters",
                link: "Short Constructive Response Q4",
                parentId: 135
            },
            {
                id: 1256,
                label: "Extended Rich Text - With toolbar",
                link: "Short Constructive Response Q5",
                parentId: 135
            },
            {
                id: 1257,
                label: "Extended Rich Text - Toolbar with Spanish char and Spell checker",
                link: "Short Constructive Response Q6",
                parentId: 135
            }
        ]
    },
    {
        id: 136,
        label: "Chart",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1258,
                label: "Line Chart",
                link: "Line Chart",
                parentId: 136
            },
            {
                id: 1259,
                label: "Bar Chart - Vertical type",
                link: "Bar Chart - Vertical type",
                parentId: 136
            },
            {
                id: 1260,
                label: "Bar Chart - Horizontal type",
                link: "Bar Chart - Horizontal type",
                parentId: 136
            },
            {
                id: 1261,
                label: "Bar Chart - Multiple series",
                link: "Bar Chart - Multiple series",
                parentId: 136
            },
            {
                id: 1262,
                label: "Bar Chart - Negative series",
                link: "Bar Chart - Negative series",
                parentId: 136
            },
            {
                id: 1263,
                label: "Multi item composite with charts",
                link: "Multi item composite with charts",
                parentId: 136
            },
        ]
    },
    {
        id: 137,
        label: "Essay Scorer",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1270,
                label: "Essay Scorer",
                link: "Essay Scorer",
                parentId: 137
            }
        ]
    },    
    {
        id: 138,
        label: "Audio Recorder",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1381,
                label: "Audio Recorder-Review Allowed-Single Attempt",
                link: "Audio Recorder-Review Allowed-Single Attempt",
                parentId: 138
            },
            {
                id: 1382,
                label: "Audio Recorder-Review Not Allowed-Single Attempt",
                link: "Audio Recorder-Review Not Allowed-Single Attempt",
                parentId: 138
            },
            {
                id: 1383,
                label: "Audio Recorder-Review Allowed-Multiple Attempts",
                link: "Audio Recorder-Review Allowed-Multiple Attempts",
                parentId: 138
            },
            {
                id: 1384,
                label: "Audio Recorder-Review Not Allowed-Multiple Attempts",
                link: "Audio Recorder-Review Not Allowed-Multiple Attempts",
                parentId: 138
            },
            {
                id: 1385,
                label: "Audio Recorder-Max duration 300 seconds",
                link: "Audio Recorder-Max duration 300 seconds",
                parentId: 138
            }
        ]
    },
    {
        id: 139,
        label: "Score accent",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1391,
                label: "Score accent fib input 1",
                link: "Score accent fib input 1",
                parentId: 139
            },
            {
                id: 1392,
                label: "Score accent fib input 2",
                link: "Score accent fib input 2",
                parentId: 139
            },
            {
                id: 1393,
                label: "Score accent fib input 3",
                link: "Score accent fib input 3",
                parentId: 139
            },
            {
                id: 1394,
                label: "Score accent fib input 4",
                link: "Score accent fib input 4",
                parentId: 139
            },
        ]
    },
    {
        id: 140,
        label: "Multi Part",
        icon: "mdi-file-document-box-outline",
        subItems: [
            {
                id: 1401,
                label: "Multi part partial question",
                link: "Multi part partial question",
                parentId: 140
            },
            {
                id: 1402,
                label: "Multi part binary question",
                link: "Multi part binary question",
                parentId: 140
            }
        ]
    },  
    {
        id: 10,
        label: "Other",
        isTitle: true
    },
    {
        id: 11,
        label: "Changelog",
        icon: "mdi-import",
        link: "changelog"
    },

    {
        id: 12,
        label: "View XML",
        icon: "mdi-apps",
        link: "view-xml"
    },
];
